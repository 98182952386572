import React from 'react';
import { Col } from 'react-bootstrap';
import { fields } from './reportFields/reportFields';
import ReportField from './reportFields/ReportField';

export default function RegisterSection({ register, reportTotals, setReportTotals, disabled }) {
  const setRegisterTotals = (key, value) => {
    setReportTotals({
      ...reportTotals,
      [register]: { ...reportTotals[register], [key]: value },
    });
  };

  const formatTitle = () => `Register ${register.substring(3, 4)}`;

  return (
    <Col sm={4}>
      <h2 className='display-6 bg-secondary p-2 text-white'>{formatTitle()}</h2>
      {fields.map((field, idx) => (
        <ReportField
          key={idx}
          reportTotals={reportTotals}
          register={register}
          id={field.id}
          label={field.label}
          calculated={field.calculated}
          calculatedValue={field.calculatedValue}
          type={field.type}
          className={field.className}
          placeholder={field.placeholder}
          setRegisterTotals={setRegisterTotals}
          extraLabels={field.extraLabels}
          disableOverride={disabled}
        />
      ))}
    </Col>
  );
}
