import React from 'react';
import { Modal, Row, Col, Form, FormLabel } from 'react-bootstrap';
import { calculateGrandTotal, calculateTotalDeposit } from './reportFields';

export default function TotalSummary({ reportTotals, setReportTotals }) {
  return (
    <>
      <Modal.Body>
        <h2 className='display-6 bg-secondary p-2 text-white'>Total summary</h2>
        <Row className='mt-4 justify-content-between'>
          <Col sm={4}>
            <Form.Group className='mb-3 d-flex' controlId='onlineSalesCounted'>
              <FormLabel className='w-100'>Online sales</FormLabel>
              <Form.Control
                type='number'
                className='text-right'
                placeholder={0}
                value={reportTotals?.onlineSalesCounted || 0}
                onChange={(e) => {
                  setReportTotals({ ...reportTotals, onlineSalesCounted: parseFloat(e.target.value) });
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group className='mb-3 d-flex' controlId='grandTotalCounted'>
              <FormLabel className='w-100'>Counted grand total</FormLabel>
              <Form.Control
                disabled
                type='number'
                className='text-right'
                placeholder={0}
                value={calculateGrandTotal(reportTotals)}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group className='mb-3 d-flex' controlId='transactionCount'>
              <FormLabel className='w-100'>Sale count</FormLabel>
              <Form.Control
                type='number'
                className='text-right'
                placeholder={0}
                value={reportTotals?.transactionCount || 0}
                onChange={(e) => {
                  setReportTotals({ ...reportTotals, transactionCount: parseInt(e.target.value) });
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group className='mb-3 d-flex' controlId='saleAmountPerTransaction'>
              <FormLabel className='w-100'>Avg. transaction total</FormLabel>
              <Form.Control
                disabled
                type='number'
                className='text-right'
                placeholder={0}
                value={(calculateGrandTotal(reportTotals) / reportTotals?.transactionCount).toFixed(2)}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group className='mb-3 d-flex' controlId='grandTotalForDeposit'>
              <FormLabel className='w-100'>
                <strong>Total for deposit</strong>
              </FormLabel>
              <Form.Control
                disabled
                type='number'
                className='text-right'
                placeholder={0}
                value={calculateTotalDeposit(reportTotals)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
}
